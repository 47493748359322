/* eslint-disable no-useless-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/iframe-has-title */
import { CloseIcon, DropDown, MenuIcon, NotificationIcon } from '@atoms';
import {
  useDoctorNotification,
  useDoctorProfile,
  useOrganizationNotification,
  useOrgProfile,
  useViewPort,
} from '@hc/store';
import { OrganizationNotificationPopup } from '@hc/ui/components/organization/notificationPopup';
import { localStorageKeys } from '@hc/utils/constants';
import { isHttpURL, parseJwt } from '@hc/utils/helperFunctions';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { React, useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { UserProfileIcon } from '../icons';
import { SideBar } from '../sideBar';
import { AppBar_style } from './style';
import { useBackOfficeNotification } from '@hc/store/backoffice/notification';
import { backofficeRoutes } from '@hc/routes';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export function AppBar(props) {
  const {
    rootStyle = {},
    className = '',
    navItems = [],
    onOpen = () => {},
    onClose = () => {},
    Organizationlogo,
    organizationName = '',
    divider,
    userDetail,
    openDrawer,
    dropDownCng = false,
    menuItems = [],
    anchorOriginStyle,
    transformOriginStyle = {},
    menuStyle,
    isRxAudit,
    menuItemStyle,
    menuIconStyle,
    OrganizationNotificationPopupTitle = '',
    showNotification = true,
    version,
    profileOpen,
    navStyle = {},
    handleHome,
    ...rest
  } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  console.log(data);
  const navigate = useNavigate();

  const {
    getOrganizationNotification,
    notificationState,
    softDeleteNotification,
  } = useOrganizationNotification(
    (state) => ({
      getOrganizationNotification: state.getOrganizationNotification,
      notificationState: state.notificationState,
      softDeleteNotification: state.softDeleteNotification,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { backOfficeNotificationState, getBackOfficeNotification } =
    useBackOfficeNotification(
      (state) => ({
        backOfficeNotificationState: state.backOfficeNotificationState,
        getBackOfficeNotification: state.getBackOfficeNotification,
      }),
      (prev, curr) => isEqual(prev, curr),
    );

  const { getDoctorNotification, doctorNotificationState } =
    useDoctorNotification(
      (state) => ({
        getDoctorNotification: state.getDoctorNotification,
        doctorNotificationState: state.doctorNotificationState,
        loading: state.loading,
      }),
      (prev, curr) => isEqual(prev, curr),
    );

  const { profileView, getProfileView } = useOrgProfile((state) => ({
    profileView: state.profileView,
    getProfileView: state.getProfileView,
  }));

  // Store Data
  const { refreshAuthToken } = useDoctorProfile((state) => ({
    refreshAuthToken: state.refreshAuthToken,
  }));
  const { isMobilePort } = useViewPort((state) => ({
    isMobilePort: state.isMobilePort,
  }));

  const { notificationData } = notificationState;
  const { doctorNotificationData } = doctorNotificationState;
  const { backOfficeNotificationData } = backOfficeNotificationState;

  // NEW STATE
  const [dropDown, setDropDown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [notificationsState, setNotificationsState] = useState(false);

  const open = Boolean(dropDown);
  const id = open ? 'simple-popover' : undefined;

  const dropDownOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setDropDown(true);
  };
  const dropDownClose = (menuFun) => {
    setAnchorEl(null);
    setDropDown(false);
    menuFun();
  };

  const notificationCloseFnc = () => {
    setNotificationsState(false);
  };

  const notificationOpenFnc = () => {
    if (data?.role_id === 4) {
      getOrganizationNotification(data?.user_id);
    } else if (data?.role_id === 2 || data?.role_id === 7) {
      getDoctorNotification(data?.user_id);
    }
    setNotificationsState(true);
  };

  const refreshAuthTokenFnc = async () => {
    const responseCode = await refreshAuthToken();
    if (responseCode === 200) {
      if (data?.role_id === 2) {
        toast('Your account is verified... Redirecting to availablity page...');
        setTimeout(() => {
          return navigate('/availability/?verificationDone=true');
        }, 2000);
      } else if (data?.role_id === 7) {
        toast('Your account is verified... Redirecting to home page...');
        setTimeout(() => {
          return navigate('/');
        }, 2000);
      } else {
        toast('Your account is verified... Redirecting to home page...');
        setTimeout(() => {
          return window.location.reload();
        }, 2000);
      }
    }
  };

  const redirectFnc = async (url, val) => {
    if (val?.is_actionable === false && val?.is_read === false) {
      await softDeleteNotification(val?.id);
      await getDoctorNotification(data?.user_id);
    }
    if (isHttpURL(url)) {
      window.open(url, '_blank');
    } else if (
      val?.is_read === false &&
      (url === '/?verificationDone=true' ||
        url === '/availability/?verificationDone=true')
    ) {
      setNotificationsState(false);
      refreshAuthTokenFnc();
    } else if (url?.length > 0 && url?.startsWith('/planStatus/:id')) {
      const user_plan_id = url.replace('/planStatus/:id=', '');
      if (user_plan_id?.length > 0) {
        notificationCloseFnc();
        navigate(backofficeRoutes?.planStatus, {
          state: {
            id: user_plan_id,
          },
        });
      }
    } else if (url === '/?referral=true') {
      setNotificationsState(false);
      navigate(url, {
        state: {
          fromNotification: 'referral',
        },
      });
    } else if (url === '/?appointments=true') {
      setNotificationsState(false);
      navigate(url, {
        state: {
          fromNotification: 'appointments',
        },
      });
    } else {
      setNotificationsState(false);
      navigate(url);
    }
  };

  // GET NOTIFICATION DATA
  useEffect(() => {
    if (data?.user_id) {
      if (data?.role_id === 4) {
        getOrganizationNotification(data?.user_id);
      } else if (data?.role_id === 2) {
        getDoctorNotification(data?.user_id);
      }
    } else if (data?.profile?.user_id && data?.role_id === 1) {
      getBackOfficeNotification(data?.profile?.user_id);
    }
  }, []);

  useEffect(() => {
    getProfileView();
  }, []);

  useEffect(() => {
    setDropDown(dropDownCng);
  }, [dropDownCng]);

  useEffect(() => {
    if (
      Array.isArray(doctorNotificationData) &&
      doctorNotificationData?.length > 0
    ) {
      const verfifyArr = doctorNotificationData?.filter(
        (val) =>
          (JSON.parse(val?.content)?.actionUrl ===
            '/availability/?verificationDone=true' ||
            JSON.parse(val?.content)?.message?.includes(
              'Your Verification process is done, Please avail the service.',
            )) &&
          val?.is_read === false,
      );
      if (verfifyArr?.length > 0) {
        setTimeout(() => {
          softDeleteNotification(verfifyArr?.[0]?.id);
          setNotificationsState(false);
          refreshAuthTokenFnc();
        }, 3000);
      }
    }
  }, [doctorNotificationData]);

  return (
    <Box
      sx={{ ...AppBar_style.rootSx, ...rootStyle }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          ...AppBar_style.navSx,
          ...navStyle,
        }}
      >
        <Stack direction="row" spacing={1}>
          <Box
            pl={1}
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              ...menuIconStyle,
            }}
          >
            {openDrawer ? (
              <IconButton
                disableRipple
                aria-label="open drawer"
                onClick={onClose}
                color="inherit"
              >
                <CloseIcon
                  rootStyle={{ height: 20, width: 24, color: '#5C6266' }}
                />
              </IconButton>
            ) : (
              <IconButton
                aria-label="open drawer"
                onClick={onOpen}
                color="inherit"
                disableRipple
              >
                <MenuIcon
                  rootStyle={{ height: 24, width: 24, color: '#5C6266' }}
                />
              </IconButton>
            )}
          </Box>
          <Box sx={AppBar_style.logoSx} onClick={handleHome}>
            {Organizationlogo}
            <Box sx={{ display: 'grid', ml: 1 }}>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 600,
                  fontSize: '16px',
                }}
              >
                {organizationName}
              </Typography>
              <Typography
                sx={{
                  fontSize: '10px',
                  color: '#888888',
                }}
              >
                {`Version: ${version}`}
              </Typography>
            </Box>
          </Box>
        </Stack>

        <Stack direction="row" spacing={2} sx={AppBar_style.loginSx}>
          {showNotification && (
            <IconButton onClick={() => notificationOpenFnc()}>
              <Badge
                color="error"
                overlap="circular"
                sx={AppBar_style.notificationSx}
                variant={
                  (data?.role_id === 4
                    ? notificationData?.filter((val) => val?.is_read === false)
                    : doctorNotificationData?.filter(
                        (val) => val?.is_read === false,
                      )
                  )?.length > 0
                    ? 'dot'
                    : 'standard'
                }
              >
                <NotificationIcon rootStyle={{ width: 20, height: 20 }} />
              </Badge>
            </IconButton>
          )}
          {divider ? (
            <Divider
              orientation="vertical"
              flexItem
              variant="middle"
              sx={{ color: '#5C6266' }}
            />
          ) : (
            ''
          )}

          {!isMobilePort && (
            <Box sx={{ display: 'flex', gap: '12px' }}>
              {userDetail ? (
                <>
                  <Box sx={{ cursor: 'pointer' }}>
                    <Avatar
                      src={profileView?.logo?.downloadUrl}
                      sx={AppBar_style.avatarSx}
                      onClick={(event) => dropDownOpen(event)}
                    />
                  </Box>{' '}
                  <Box onClick={(event) => dropDownOpen(event)}>
                    <Typography sx={AppBar_style.adminNameSx}>
                      {userDetail.userName}
                    </Typography>
                    <Typography sx={AppBar_style.roleSx}>
                      {userDetail.userRole}
                    </Typography>
                  </Box>
                  <DropDown
                    id="account"
                    rootStyle={{
                      cursor: 'pointer',
                      mt: '10px',
                    }}
                    onClick={(event) => dropDownOpen(event)}
                    aria-describedby={id}
                  />
                </>
              ) : (
                <IconButton onClick={(event) => dropDownOpen(event)}>
                  <Box
                    id="profile"
                    onClick={(event) => dropDownOpen(event)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      cursor: 'pointer',
                    }}
                  >
                    <UserProfileIcon
                      rootStyle={{ color: '#0e0e0e' }}
                      onClick={(event) => dropDownOpen(event)}
                      aria-describedby={id}
                    />
                  </Box>
                </IconButton>
              )}
            </Box>
          )}
          {isMobilePort && (
            <Box sx={{ display: 'flex', gap: '12px' }}>
              {userDetail ? (
                <>
                  <Box sx={{ cursor: 'pointer' }}>
                    <Avatar
                      src={profileView?.logo?.downloadUrl}
                      sx={AppBar_style.avatarSx}
                      onClick={(event) => profileOpen(event)}
                    />
                  </Box>{' '}
                  <Box onClick={(event) => profileOpen(event)}>
                    <Typography sx={AppBar_style.adminNameSx}>
                      {userDetail.userName}
                    </Typography>
                    <Typography sx={AppBar_style.roleSx}>
                      {userDetail.userRole}
                    </Typography>
                  </Box>
                </>
              ) : (
                <IconButton onClick={(event) => profileOpen(event)}>
                  <Box
                    id="profile"
                    onClick={(event) => profileOpen(event)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      cursor: 'pointer',
                    }}
                  >
                    <UserProfileIcon
                      rootStyle={{ color: '#0e0e0e' }}
                      onClick={(event) => profileOpen(event)}
                      aria-describedby={id}
                    />
                  </Box>
                </IconButton>
              )}
            </Box>
          )}

          {isRxAudit && isMobilePort && (
            <Box sx={{ display: 'flex', gap: '12px' }}>
              <IconButton onClick={(event) => dropDownOpen(event)}>
                <Box
                  onClick={(event) => dropDownOpen(event)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                >
                  <UserProfileIcon
                    rootStyle={{ color: '#0e0e0e' }}
                    onClick={(event) => dropDownOpen(event)}
                    aria-describedby={id}
                  />
                </Box>
              </IconButton>
            </Box>
          )}

          <Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={dropDownClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{ ...AppBar_style.menuSx, ...menuStyle }}
              anchorOrigin={{
                ...AppBar_style.anchorOriginSx,
                ...anchorOriginStyle,
              }}
              transformOrigin={{
                ...transformOriginStyle,
              }}
            >
              {menuItems?.map((item, i) => (
                <MenuItem
                  id={`item${i}`}
                  border={1}
                  key={item.name}
                  onClick={() => dropDownClose(item.onclick)}
                  sx={{ ...AppBar_style.MenuItemSx, ...menuItemStyle }}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Stack>
      </Box>
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,

          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={openDrawer}
      >
        <DrawerHeader>
          <IconButton sx={{ mb: 1 }} onClick={onClose} />
        </DrawerHeader>

        <Box onClick={onClose}>
          <SideBar navItems={navItems} />
        </Box>
      </Drawer>
      {/* PROFILE POPOVER */}

      {/* NOTIFICATION POPOVER */}
      <OrganizationNotificationPopup
        title={OrganizationNotificationPopupTitle}
        openPop={notificationsState}
        redirectFnc={redirectFnc}
        notificationOpenCloseFnc={notificationCloseFnc}
        notificationData={
          data?.role_id === 4
            ? notificationData
            : data?.role_id === 1
            ? backOfficeNotificationData
            : doctorNotificationData
        }
        roleID={data?.role_id}
        userId={data?.user_id}
      />
    </Box>
  );
}
AppBar.propTypes = {
  userDetail: PropTypes.object,
  rootStyle: PropTypes.object,
  className: PropTypes.string,
  navItems: PropTypes.array,
  isRxAudit: PropTypes.bool,
  // logo: PropTypes.object,
  dropDownCng: PropTypes.bool,
  Organizationlogo: PropTypes.object,
  organizationName: PropTypes.string,
  divider: PropTypes.bool,
  menuItems: PropTypes.array,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  openDrawer: PropTypes.bool,
  anchorEl: PropTypes.func,
};
